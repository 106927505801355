import { defineStore, acceptHMRUpdate } from 'pinia'
import { Dialog, LoadingBar, Notify } from 'quasar'
import { MessageType } from '@/Enums/MessageType'
import { createId } from '@paralleldrive/cuid2'
import { MessageStatus } from '@/Constants'
import VueScrollTo from 'vue-scrollto'
import Conversation from '@/Core/Conversation'

import EraseMessage from '@Components/Dialog/EraseMessage.vue'

export const useActiveChatStore = defineStore('active-chat', {
  state: () => ({
    currentContact: null as unknown as Conversation,
    currentScheduleMessageEdit:
      null as unknown as IPChat.ScheduledMessages | null,
  }),
  getters: {
    needToAssign: (state) =>
      state.currentContact?.phase === 'attendant_assignment',
    getFirstMessageId: (state) => {
      const conversations = state.currentContact?.messages
      if (conversations) {
        return conversations[0].id
      }
    },
    lastMessageIdOrKey: (state) => {
      const conversations = state.currentContact?.messages

      if (conversations?.length) {
        const message = conversations[conversations.length - 1]
        return message.id || message.key
      }
    },
    isContactOpen: (state) => {
      return (stateId: number) => state.currentContact?.stateId === stateId
    },
    sameUser: (state) => {
      const useAuth = useAuthStore()
      return state.currentContact?.attendantId === useAuth.user.id
    },
    isGroup: (state) => state.currentContact?.props.state?.is_group,
    getId: (state) => state.currentContact?.contactId,
    getStateId: (state) => state.currentContact?.stateId,
    getInstanceId: (state) => state.currentContact?.instanceId,
    getName: (state) => state.currentContact?.props.contact.name,
    getUsername: (state) => state.currentContact?.props.contact.number,
    isQuotedState: (state) => state.currentContact?.quotedState,
    isWithBot: (state) =>
      state.currentContact?.props.state.phase === 'bot_await_response',
  },
  actions: {
    clear() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.currentContact = null
    },
    isAttendantAssignmentPhase(phase: string): boolean {
      return [ 'attendant_assignment', 'bot_await_response', 'init' ].includes(
        phase
      )
    },
    finishConversation() {
      const conversationStore = useConversationsStore()
      conversationStore.openFinalizeConversation({
        conversation: this.currentContact,
      })
    },
    transferConversation() {
      const conversationStore = useConversationsStore()
      conversationStore.openTransferConversation({
        conversation: this.currentContact,
      })
    },
    clearScheduleMessageEdit() {
      const modalStore = useModalsStore()
      modalStore.openMessageSchedulingEditModal = false
      this.currentScheduleMessageEdit = null
      this.getSchedules()
    },
    toggleTagAssign() {
      const modalStore = useModalsStore()
      modalStore.openTagAssign = !modalStore.openTagAssign
    },
    toggleMessageSchedulingModal() {
      const modalStore = useModalsStore()
      modalStore.openMessageSchedulingModal =
        !modalStore.openMessageSchedulingModal
    },
    toggleMessageSchedulingViewModal() {
      const modalStore = useModalsStore()
      modalStore.openMessageSchedulingViewModal =
        !modalStore.openMessageSchedulingViewModal
    },
    toggleCommentsModal() {
      const modalStore = useModalsStore()
      modalStore.openCommentsModal = !modalStore.openCommentsModal
    },
    openContactEditModal() {
      const modalStore = useModalsStore()
      modalStore.openContactEdit = !modalStore.openContactEdit
    },
    scheduleMessageEdit(props: { message: ScheduledMessages }) {
      const modalStore = useModalsStore()
      modalStore.openMessageSchedulingEditModal =
        !modalStore.openMessageSchedulingEditModal
      this.currentScheduleMessageEdit = props.message
    },
    replyMessage(message?: IPChat.PartialMessage) {
      if (!message) {
        this.currentContact.quotedState = false
        this.currentContact.quotedMessage = undefined
        return
      }

      if (this.currentContact) {
        this.currentContact.quotedState = true
        this.currentContact.quotedMessage = {
          message,
        }
      }
    },
    async revokeMessage(props: { messageKey: string; stateId: number }) {
      const { messageKey, stateId } = props

      Dialog.create({
        persistent: false,
        stackButtons: true,
        component: EraseMessage,
        focus: 'none',
      }).onOk(async () => {
        try {
          await useEraseMessage({ messageKey, stateId })
        } catch (e: any) {
          const error =
            e.response.data.error || 'Houve um erro, tente novamente!'
          Notify.create({
            type: 'error',
            message: error,
          })
        }
      })
    },
    async getConversationFromBot() {
      try {
        const { data: state } = await window.axios.post<IPChat.State>(
          route('v2.state.getFromBot', {
            _query: { stateId: this.getStateId },
          })
        )
        const stateStore = useStateStore()

        const conversationStore = useConversationsStore()

        const conversation =
          await conversationStore.getConversationByIdAndInstanceId({
            contactId: this.getId,
            instanceId: this.getInstanceId,
          })

        if (conversation) {
          state.phase = 'attendant'

          if (stateStore.getState(state.id)) {
            stateStore.updateState({ state })
          } else {
            stateStore.appendNewState({ state })
          }

          conversation.state = state

          conversationStore.list.active.unshift(conversation)

          const contactStore = useContactStore()
          contactStore.clearFilter()
        }
      } catch (e: any) {
        const error =
          'error' in e.response.data
            ? e.response.data.error
            : 'message' in e.response.data
              ? e.response.data.message
              : 'Não foi possível iniciar o atendimento, tente novamente!'

        Notify.create({
          type: 'error',
          message: error,
        })
      }
    },
    async startNewConversation() {
      if (!this.currentContact.isFinished) {
        Notify.create({
          type: 'error',
          message:
            'Não é possível reativar esse atendimento pois ele já está ativo',
        })
        return
      }

      try {
        const { data: state } = await window.axios.post<IPChat.State>(
          route('v2.state.reactivate', {
            _query: { stateId: this.getStateId },
          })
        )

        const stateStore = useStateStore()
        stateStore.updateState({ state })

        const conversationStore = useConversationsStore()

        const conversation =
          await conversationStore.getConversationByIdAndInstanceId({
            contactId: this.getId,
            instanceId: this.getInstanceId,
          })

        if (conversation) {
          if (conversation.isSearch) {
            conversation.state = state
          }

          conversationStore.transferFinishedToActive({
            contactId: this.getId,
            instanceId: this.getInstanceId,
          }, conversation.isSearch)
        }
      } catch (e: any) {
        const error =
          'error' in e.response.data
            ? e.response.data.error
            : 'message' in e.response.data
              ? e.response.data.message
              : 'Não foi possível iniciar o atendimento, tente novamente!'

        Notify.create({
          type: 'error',
          message: error,
        })
      }
    },
    async assignAttendant(props: { stateId: number }) {
      const { stateId } = props
      await useAssignAttendant({ stateId })
    },
    async assignConversationToObserver() {
      const conversation = this.currentContact

      if (!conversation) {
        Notify.create({
          type: 'error',
          message: 'Houve um erro ao atribuir o atendimento, tente novamente!',
        })
        return
      }

      const conversationsStore = useConversationsStore()
      const conversationFound =
        await conversationsStore.getConversationByIdAndInstanceId({
          contactId: conversation.contactId,
          instanceId: conversation.instanceId,
        })

      if (!conversationFound) return

      const auth = useAuthStore()

      const assignAttendantIfNeeded = async (conv: Conversation) => {
        if (!conv.isGroup && this.isAttendantAssignmentPhase(conv.phase)) {
          await this.assignAttendant({ stateId: conv.stateId })
          conv.props.state.phase = 'attendant'
          conv.props.state.attendant_id = auth.user.id
        }
      }

      const fetchMessagesIfNeeded = (conv: Conversation) => {
        if (!conv.hasMessages) {
          conversationsStore.fetchMessages({
            contactId: conv.contactId,
            instanceId: conv.instanceId,
          })
        }
      }

      await assignAttendantIfNeeded(conversationFound)
      fetchMessagesIfNeeded(conversationFound)

      if (conversationFound.pendingToRead) {
        if (this.sameUser && !conversationFound.isGroup) {
          markAsRead(conversationFound.stateId)
        } else if (conversationFound.isGroup && this.lastMessageIdOrKey) {
          markGroupAsRead(conversationFound.contactId, this.lastMessageIdOrKey)
        }
        conversationFound.pendingToRead = false
      }

      //this.currentContact = conversationFound
    },
    async setCurrentConversation(props: { conversation: Conversation }) {
      const { conversation } = props

      const conversationsStore = useConversationsStore()
      const conversationFound =
        await conversationsStore.getConversationByIdAndInstanceId({
          contactId: conversation.contactId,
          instanceId: conversation.instanceId,
        })

      if (!conversationFound) return

      const auth = useAuthStore()

      const assignAttendantIfNeeded = async (conv: Conversation) => {
        if (auth.isHimself && this.isAttendantAssignmentPhase(conv.phase)) {
          if (!conv.isGroup) {
            await this.assignAttendant({ stateId: conv.stateId })
            conv.props.state = {
              ...conv.props.state,
              phase: 'attendant',
              attendant_id: auth.user.id,
            }
          }
        }
      }

      const fetchMessagesIfNeeded = (conv: Conversation) => {
        if (!conv.hasMessages) {
          conversationsStore.fetchMessages({
            contactId: conv.contactId,
            instanceId: conv.instanceId,
          })
        }
      }

      if (!this.isContactOpen(conversationFound.stateId)) {
        await assignAttendantIfNeeded(conversationFound)
        fetchMessagesIfNeeded(conversationFound)

        if (conversationFound.pendingToRead) {
          if (
            conversationFound.attendantId === auth.user.id &&
            !conversationFound.isGroup
          ) {
            conversationFound.pendingToRead = false
            markAsRead(conversationFound.stateId)
          } else if (conversationFound.isGroup) {
            const conversations = conversationFound?.messages
            const lastMessage = conversations[conversations.length - 1]
            if (lastMessage) {
              const lastMessageIdOrKey = lastMessage.id || lastMessage.key

              if (lastMessageIdOrKey) {
                conversationFound.pendingToRead = false
                markGroupAsRead(conversationFound.contactId, lastMessageIdOrKey)
              }
            }
          }
        }

        this.currentContact = conversationFound
      }
    },
    async loadMoreMessages() {
      try {
        const { contactId, instanceId } = this.currentContact
        const { data: messages } = await window.axios.get<{
          data: IPChat.PartialMessage[]
          total_messages: number
        }>(
          route('v2.message.index', {
            contact: contactId,
            _query: {
              firstMessageId: this.getFirstMessageId,
              instanceId: instanceId,
              withCount: true,
            },
          })
        )

        this.currentContact.totalMessages = messages.total_messages
        this.currentContact.props.messages = messages.data
          .reverse()
          .concat(this.currentContact.props.messages)
      } catch (e) {
        console.error(e)
        Notify.create({
          type: 'error',
          message: 'Houve um erro ao carregar as mensagens, tente novamente!',
        })
      }
    },
    async goToMessage(props: { seekMessageId: number }) {
      const { seekMessageId } = props

      const conversationsStore = useConversationsStore()

      const $scroll = () => {
        const element = `#message_${seekMessageId}`
        VueScrollTo.scrollTo(element, 500, {
          container: '#chatContainer',
          easing: 'linear',
          onDone: () => {
            document.querySelector(element)?.classList.add('message-found')
            setTimeout(() => {
              document.querySelector(element)?.classList.remove('message-found')
            }, 1000)
          },
        })
      }

      const currentContact = this.currentContact
      const contact = await conversationsStore.getConversationByIdAndInstanceId(
        {
          contactId: currentContact.props.contact.id,
          instanceId: currentContact.props.state.instance_id,
        }
      )

      if (!contact) return

      if (this.getFirstMessageId && seekMessageId < this.getFirstMessageId) {
        LoadingBar.start()
        const conversations = await useSeekMessages({
          contact: contact.contactId,
          instanceId: this.currentContact.instanceId,
          firstMessageId: this.getFirstMessageId,
          seekMessageId,
        })

        // REVISAR
        // const conversationLength = conversations.value.length
        // const parsedTotalMessages = Number(contact.totalMessages)

        // contact.totalMessages =
        //   parsedTotalMessages - conversationLength <= 0
        //     ? 0
        //     : parsedTotalMessages - conversationLength

        if (conversations && conversations.value) {
          // REVISAR
          contact.props.messages = conversations.value
            .reverse()
            .concat(contact.messages)
        }

        LoadingBar.stop()

        await waitFor(0.3)
        $scroll()
        return
      }

      $scroll()
    },
    async getSchedules() {
      try {
        const schedules = await window.axios.get<ScheduledMessages[]>(
          route('message.schedule.getByInstance', {
            contact: this.currentContact.props.contact.id,
            instance: this.currentContact.props.state.instance_id,
          })
        )
        this.currentContact.props.contact.schedulesList = schedules.data
      } catch (e) {
        //
      }
    },
    async addNewMessage(props: {
      message: IPChat.MessageContent
      type: MessageType
      media?: File
      senderName?: string
      quotedMessage?: any
    }) {
      const { message, type, media, senderName, quotedMessage } = props
      const conversationsStore = useConversationsStore()

      const conversation =
        await conversationsStore.getConversationByIdAndInstanceId({
          contactId: this.getId,
          instanceId: this.getInstanceId,
        })

      if (!conversation) return

      const key = createId()
      const messageObject: IPChat.PartialMessage = {
        key,
        message,
        type,
        fromContact: false,
        chat_state_id: this.getStateId,
        sender_name: senderName,
        created_at: new Date(),
        status: MessageStatus.PENDING,
        ...quotedMessage,
      }

      const event = new PublishMessageEvent({
        message: messageObject,
        conversation,
      })

      conversationsStore.sortContactList({
        contactId: this.getId,
        instanceId: this.getInstanceId,
      })

      conversation.messages.push(messageObject)

      const refOfMessage = conversationsStore.getMessageByKey({
        contact: conversation,
        messageKey: key,
      })

      if (type === MessageType.TEXT) {
        event.sendText()
      } else {
        if (!media) throw new Error('Media is required')
        event.sendFile({
          media,
          refOfMessage,
        })
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useActiveChatStore, import.meta.hot))
}
