import CreateNewUser from '@/Components/Dialog/CreateNewUser.vue'
import { router } from '@inertiajs/vue3'
import { defineStore } from 'pinia'
import { Dialog } from 'quasar'

export const useDashboardStore = defineStore('dashboard-store', {
  state: () => ({
    menu: {
      miniState: useStorage('menu.miniState', false)
    },
    contacts: {
      count: 0,
      fetching: false,
    },
    groups: {
      count: 0,
      fetching: false,
    },
    attendants: {
      count: 0,
      fetching: false,
    },
    messages: {
      count: 0,
      fetching: false,
    },
    scheduledMessages: {
      messageList: [] as ScheduledMessages[],
      fetching: false,
    },
  }),
  actions: {
    createNewUser() {
      const $openRoute = () =>
        router.visit(route('dashboard.user.create'), { replace: true })
      const useAuth = useAuthStore()
      const modalStore = useModalsStore()

      if (useAuth.hasManyCompanies) {
        Dialog.create({
          component: CreateNewUser,
          persistent: true,
        }).onOk((response) => {
          if (response === 'new_user') {
            $openRoute()
          } else if (response === 'import_user') {
            modalStore.toggleCreateNewUserModal()
          }
        })
        return
      }

      $openRoute()
    },
    fetchContactsCount() {
      this.contacts.fetching = true
      window.axios
        .get(route('dashboard.metrics.contactCount'))
        .then((response) => {
          this.contacts.count = response.data.count
        })
        .finally(() => {
          this.contacts.fetching = false
        })
    },
    fetchGroupsCount() {
      this.groups.fetching = true
      window.axios
        .get(route('dashboard.metrics.groupCount'))
        .then((response) => {
          this.groups.count = response.data.count
        })
        .finally(() => {
          this.groups.fetching = false
        })
    },
    fetchAttendantsCount() {
      this.attendants.fetching = true
      window.axios
        .get(route('dashboard.metrics.userCount'))
        .then((response) => {
          this.attendants.count = response.data.count
        })
        .finally(() => {
          this.attendants.fetching = false
        })
    },
    fetchMessagesCount() {
      this.messages.fetching = true
      window.axios
        .get(route('dashboard.metrics.messageCount'))
        .then((response) => {
          let total = 0
          response.data.count.forEach((item: any) => {
            total += item.messages_count
          })
          this.messages.count = total
        })
        .finally(() => {
          this.messages.fetching = false
        })
    },
    fetchScheduledMessages() {
      this.scheduledMessages.fetching = true
      window.axios
        .get<ScheduledMessages[]>(route('dashboard.loadScheduledMessages'))
        .then((response) => {
          this.scheduledMessages.messageList = response.data
        })
        .finally(() => {
          this.scheduledMessages.fetching = false
        })
    },
  },
})
