<script setup lang="ts">
import { useDialogPluginComponent } from 'quasar'

defineEmits([ ...useDialogPluginComponent.emits ])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card>
      <q-card-section>
        <div class="text-h6">Cadastrar usuário</div>
        <div>Deseja cria um novo usuário ou importar de outra empresa?</div>
      </q-card-section>

      <!-- buttons example -->
      <q-card-actions vertical align="right">
        <q-btn
          flat
          color="primary"
          label="Cadastrar novo usuário"
          @click="onDialogOK('new_user')"
        />
        <q-btn
          flat
          color="primary"
          label="Importar usuário"
          @click="onDialogOK('import_user')"
        />
        <q-btn flat color="red" label="Fechar" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
